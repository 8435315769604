/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* TOAST NOTIFICATION */
import { toast } from "react-toastify";

import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORT */
import { newsletterAPI } from "../../service/api";

/* VALIDATION */
const validationSchema = yup.object().shape({
    email: yup.string().email().required("Email address is required."),
});
const NewsLetterForm = () => {
    /* STATE VARIABLES */
    const [loading, setLoading] = useState(false);

    /* FORM HANDLING */
    const contactForm = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            newsletterAPI(values)
                .then((res) => {
                    if (res?.status) {
                        toast(
                            "Your details have been captured. We will get back to you shortly."
                        );
                    } else {
                        toast.error(res?.message);
                    }
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    toast.error(e?.response?.data?.message);
                })
                .finally(() => {
                    contactForm.handleReset();
                    setLoading(false);
                });
        },
    });

    return <form className="mt-3" onSubmit={contactForm.handleSubmit}>
        <input
            type="email"
            name="email"
            id="newsletterEmail"
            placeholder="Email address"
            className={contactForm.errors.email && contactForm.touched.email ? "w-full block border border-red-300 font-proxima-regular text-base text-black px-4 py-2 rounded focus:outline-none" : "w-full block border border-white font-proxima-regular text-base text-black px-4 py-2 rounded focus:outline-none"}
            value={contactForm.values.email}
            onChange={contactForm.handleChange}
        />
        <Button
            type="submit" disabled={loading}
            className="w-full mt-2 bg-white shadow-none hover:shadow-none text-black font-proxima-semibold font-semibold text-base normal-case py-2 rounded"
        >
            {loading ? "Please wait..." : "Subscribe"}
        </Button>
    </form>
}
export default NewsLetterForm;