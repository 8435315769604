import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* ICONS */
import { IoIosCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import ContactUsForm from "../../components/forms/contactUs";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const ContactUs = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* HELMET */}
      <Helmet>
        <title>Contact Us | Joy of others Support Services</title>
      </Helmet>

      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-10 lg:gap-10 items-center">
            {/* APPOINTMENT FORM */}
            <div className="col-span-1">
              <div className="container !p-0 xl:!p-5 lg:!p-5">
                <div className="ml-0 xl:ml-10 lg:ml-10">
                  <h1 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black tracking-tight leading-tight xl:leading-tight lg:leading-tight">
                    SCHEDULE AN APPOINTMENT WITH OUR{" "}
                    <span className="text-logoOrange">SUPPORT SERVICES</span>{" "}
                    <span className="text-logoPurple">EXPERT</span>
                  </h1>
                  <h2 className="text-lg font-proxima-regular text-greyText mt-5">
                    Contact us today for expert guidance in support services and
                    achieve your goals. Your wellness journey begins with a
                    conversation.
                  </h2>
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                    <div className="col-span-1 w-full bg-logoBlue bg-opacity-5 border border-[#E5E5E5] p-5 rounded-xl">
                      <IoIosCall className="w-9 h-9 text-logoRed" />
                      <h5 className="mt-3 text-black font-proxima-semibold text-base">
                        Call us from Monday to Friday
                      </h5>
                      <a
                        href="tel:+61425170827"
                        className="block text-xl font-proxima-bold text-black mt-1 hover:text-logoRed"
                      >
                        +61 (425) 170-827
                      </a>
                    </div>
                    <div className="col-span-1 w-full bg-logoBlue bg-opacity-5 border border-[#E5E5E5] p-5 rounded-xl">
                      <MdEmail className="w-9 h-9 text-logoRed" />
                      <h5 className="mt-3 text-black font-proxima-semibold text-base">
                        Email us at
                      </h5>
                      <a
                        href="mailto:info@joyofothers.com.au"
                        className="block text-xl font-proxima-bold text-black mt-1 hover:text-logoRed"
                      >
                        info@joyofothers.com.au
                      </a>
                    </div>
                  </div>
                  <div className="rounded-xl bg-logoBlue bg-opacity-5 border border-[#E5E5E5] p-5 xl:p-10 lg:p-10 mt-5">
                    <ContactUsForm />
                  </div>
                </div>
              </div>
            </div>

            {/* LOCATION DETAILS */}
            <div className="col-span-1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3535.401461505492!2d152.9526117!3d-27.6120804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b914ecf76d71cef%3A0x8f6cc4c2920b73ce!2s4%20Jacaranda%20Cl%2C%20Ellen%20Grove%20QLD%204078%2C%20Australia!5e0!3m2!1sen!2sin!4v1708680171118!5m2!1sen!2sin"
                className="w-full h-[40vh] xl:h-[88vh] lg:h-[88vh] border border-[#E5E5E5] rounded-lg shadow-xl"
                title="Clinic Address"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default ContactUs;
