import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import CallToAction from "../../components/call-to-action";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const DailyTasks = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* PAGE TITLE */}
      <Helmet>
        <title>Daily Tasks | Joy of others Support Services</title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/daily-tasks/daily-tasks-banner.jpeg"
              className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
              alt="About Us"
            />
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-3/5 lg:w-3/5">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-5xl lg:text-5xl leading-tight">
                    Let’s make community living easier
                  </h1>
                  <h2 className="w-full xl:w-3/5 lg:w-3/5 font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    Reduce your everyday burden, but at the same time cultivate
                    a community in our common area. It is now our task to build
                    a positive home environment for you.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT JOY OF OTHERS */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black">
                ASSISTANCE WITH DAILY TASKS
              </h4>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                A supportive environment is a thriving environment, we know that
                means the world to you. Joy of others is committed to offering
                all the assistance and supervision you need to accomplish your
                daily tasks in a shared living setting, so you can live life on
                your terms.
              </div>
              <ul
                style={{ listStyleType: "disc" }}
                className="mt-5 px-10 font-proxima-regular text-black text-lg"
              >
                <li>What will you find in our shared living arrangements?</li>
                <li>
                  A community of individuals who care about your well-being
                </li>
                <li>Meal preparation support</li>
                <li>Help with completing household chores</li>
                <li>Maintaining a clean and tidy home </li>
                <li>Providing companionship</li>
                <li>Managing your medications </li>
                <li>And more!</li>
              </ul>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                But no, we don't simply want you to complete tasks. We want to
                build connection with you so we can all experience a sense of
                belonging. So, feel free to create lasting friendships in our
                shared living environment, because we believe that life is
                better when it's shared.
              </div>
            </div>
            <div className="col-span-1">
              <div className="col-span-1">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/daily-tasks/daily-tasks-01.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Assistance with Daily Tasks / Shared Living"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/daily-tasks/daily-tasks-02.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Assistance with Daily Tasks / Shared Living"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <motion.div variants={animation} className="bg-[#F5F5F5] bg-opacity-50">
        <CallToAction />
      </motion.div>
    </motion.section>
  );
};

export default DailyTasks;
