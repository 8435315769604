import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import CallToAction from "../../components/call-to-action";

/* ASSETS */
import Banner from "../../assets/images/banners/what-is-ndis.jpg";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const WhatisNDIS = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* PAGE TITLE */}
      <Helmet>
        <title>What is NDIS? | Joy of others Support Services</title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <img
              src={Banner}
              className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
              alt="About Us"
            />
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-3/5 lg:w-3/5">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-6xl lg:text-6xl leading-none">
                    Your Guide to NDIS Excellence with{" "}
                    <span className="text-logoOrange">Joy of Others</span>
                  </h1>
                  <h2 className="font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    Unlocking possibilities with NDIS: We remain a one-stop
                    resource for support, advocacy and assistance to promote the
                    welfare of people with disability and the ability to live
                    their lives to the fullest.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT NDIS */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-2">
              <h4 className="text-2xl xl:text-3xl lg:text-3xl font-proxima-extrabold text-black leading-tight">
                WHAT DO YOU MEAN BY
              </h4>
              <h4 className="text-3xl font-proxima-extrabold text-logoOrange">
                NATIONAL DISABILITY INSURANCE SCHEME
              </h4>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                <b>National:</b> The NDIS is being introduced progressively
                across all states and territories.
              </div>
              <div className="mt-2 font-proxima-regular text-black text-lg">
                <b>Disability:</b> The NDIS provides support to eligible people
                with intellectual, physical, sensory, cognitive and psychosocial
                disability. Early intervention supports can also be provided for
                eligible people with disability or children with developmental
                delay.
              </div>
              <div className="mt-2 font-proxima-regular text-black text-lg">
                <b>Insurance:</b> The NDIS gives all Australians peace of mind
                if they, their child or loved one is born with or acquires a
                permanent and significant disability, they will get the support
                they need.
              </div>
              <div className="mt-2 font-proxima-regular text-black text-lg">
                <b>Scheme:</b> The NDIS is not a welfare system. The NDIS is
                designed to help people get the support they need so their
                skills and independence improve over time.
              </div>
              <div className="mt-2 font-proxima-regular text-black text-lg">
                The NDIS focuses on the individual and provides them with the
                choice to decide where and how to use their funding. Supports
                can be across many areas, including employment, allied health,
                accommodation, independence, outdoor activities, sport and
                community access.
              </div>
              <div className="mt-2 font-proxima-regular text-black text-lg">
                Through the NDIS, funding goes directly to the individual to
                allocate spending on the services they need from the provider of
                their choice. A plan is created for each eligible NDIS
                participant based on specific individual needs and progress
                goals.
              </div>
            </div>
            <div className="col-span-1">
              <img
                src={require("../../assets/images/ndis-logo.png")}
                className="w-full h-auto object-cover rounded-xl"
                alt="I love NDIS"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHAT IS NDIS */}
      <motion.div variants={animation} className="pb-6 xl:pb-12 lg:pb-12">
        <div className="container mx-auto">
          <h4 className="text-2xl xl:text-3xl lg:text-3xl font-proxima-extrabold text-black leading-tight">
            WHAT IS NDIS?
          </h4>
          <div className="mt-5">
            <p className="font-proxima-regular text-black text-lg">
              The National Disability Insurance Scheme, also known as the NDIS,
              is run by the National Disability Insurance Agency, also known as
              the NDIA.
            </p>
            <p className="font-proxima-regular text-black text-lg mt-2">
              The NDIS is for eligible Australians who were either born with or
              acquire a permanent and significant disability. A ‘permanent
              disability’ means a person’s disability is likely to be lifelong.
              A ‘significant disability’ means a disability with a large impact
              on a person’s ability to complete everyday activities.
            </p>
            <p className="font-proxima-regular text-black text-lg mt-2">
              The NDIS funds reasonable and necessary supports and services that
              relate to a person’s disability to help them achieve their goals.
              ‘Reasonable’ means the support is most appropriately funded or
              provided through the NDIS. And ‘necessary’ means something a
              person needs that is related to their disability.
            </p>
            <p className="font-proxima-regular text-black text-lg mt-2">
              Who will I work with to get information about the NDIS? The NDIS
              is delivered across Australia with the help of our partners in the
              community. These are organisations in your community that deliver
              the NDIS and can help you navigate the supports and services in
              your local area.
            </p>
            <div className="mt-5">
              <h5 className="text-2xl font-proxima-bold text-black">
                What does it mean to be an NDIS participant?
              </h5>
              <p className="font-proxima-regular text-black text-lg mt-2">
                If you’re eligible for the NDIS and you become an NDIS
                participant, you will receive funding to work towards the goals
                in your NDIS plan that are specific to you. Everyone has
                different goals, but they could include things like improving
                speech or communication skills through therapy, getting help for
                normal, everyday living activities or equipment to help you at
                home or in your community, learning new skills or getting and
                keeping a job, becoming more independent, or making friends and
                connections. Once you have your individual NDIS plan, you will
                work with your chosen service providers, who will support you to
                pursue your goals. Service providers are professionals who
                deliver supports and services such as physiotherapy, speech
                therapy or occupational therapy. You may also use your NDIS plan
                to work with a support worker, engage a carer, or to access
                assistive technology, or complete home modifications that help
                you in your everyday life.
              </p>
            </div>
            <div className="mt-5">
              <h5 className="text-2xl font-proxima-bold text-black">
                What if I don’t meet the eligibility requirements for the NDIS?
              </h5>
              <p className="font-proxima-regular text-black text-lg mt-2">
                If you have a disability and are not eligible for NDIS funding,
                we’ll still help you to find and access community and other
                government services. Eligibility and access to the NDIS isn’t
                the only way you can get support. The NDIS and our partners in
                the community can provide information to connect you with
                supports available in your local area, as well as information
                about what support is provided by each State and Territory
                government.
              </p>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <CallToAction />
    </motion.section>
  );
};

export default WhatisNDIS;
