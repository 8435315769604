/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* TOAST NOTIFICATION */
import { toast } from "react-toastify";

import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORT */
import { contactUsAPI } from "../../service/api";

/* SUBJECT OPTIONS */
const subjectOptions = [
  {
    label: "Assist Personal Activities",
    value: "Assist Personal Activities",
  },
  {
    label: "Assist Travel/Transport",
    value: "Assist Travel/Transport",
  },
  {
    label: "Daily Tasks/Shared Living",
    value: "Daily Tasks/Shared Living",
  },
  {
    label: "Community Participation",
    value: "Community Participation",
  },
  {
    label: "Development Life Skills",
    value: "Development Life Skills",
  },
  {
    label: "Household Tasks",
    value: "Household Tasks",
  },
  {
    label: "Group/Centre Actvities",
    value: "Group/Centre Actvities",
  },
  {
    label: "Accomodation",
    value: "Accomodation",
  },
];

/* VALIDATION */
const contactUsValidation = yup.object().shape({
  name: yup.string().required("Name is required"),
  subject: yup.object().required("Subject is required"),
  email: yup.string().email().required("Email address is required."),
  phoneNumber: yup.string().required("Phone number is required."),
  message: yup.string().required("Message is required."),
});

const ContactUsForm = () => {
  /* STATE VARIABLES */
  const [loading, setLoading] = useState(false);

  /* FORM HANDLING */
  const contactForm = useFormik({
    initialValues: {
      name: "",
      subject: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: contactUsValidation,
    onSubmit: (values) => {
      const obj = {
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        subject: values?.subject?.value,
        message: values.message,
      };
      setLoading(true);
      contactUsAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast(
              "Your details have been captured. We will get back to you shortly."
            );
          } else {
            toast.error(res?.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally(() => {
          contactForm.handleReset();
          contactForm.setFieldValue("phoneNumber", "");
          setLoading(false);
        });
    },
  });

  return (
    <form onSubmit={contactForm.handleSubmit}>
      <div className="block xl:flex lg:flex items-center gap-5">
        <div className="form-group w-full">
          <label className="text-base font-proxima-semibold text-black">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            className={
              contactForm.errors.name && contactForm.touched.name
                ? "block w-full border border-red-300 rounded px-4 py-2 mt-1 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
                : "block w-full border border-[#E5E5E5] rounded px-4 py-2 mt-1 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
            }
            placeholder="Name"
            value={contactForm.values.name}
            onChange={contactForm.handleChange}
          />
        </div>
        <div className="form-group w-full mt-5 xl:mt-0 lg:mt-0">
          <label className="text-base font-proxima-semibold text-black">
            Email address <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="email"
            className={
              contactForm.errors.email && contactForm.touched.email
                ? "block w-full border border-red-300 rounded px-4 py-2 mt-1 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
                : "block w-full border border-[#E5E5E5] rounded px-4 py-2 mt-1 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
            }
            placeholder="Email address"
            value={contactForm.values.email}
            onChange={contactForm.handleChange}
          />
        </div>
      </div>
      <div className="block xl:flex lg:flex items-center gap-5 mt-5">
        <div className="form-group w-full mt-5 xl:mt-0 lg:mt-0">
          <label className="text-base font-proxima-semibold text-black">
            Phone <span className="text-red-500">*</span>
          </label>
          <PhoneInput
            placeholder="Phone"
            defaultCountry="US"
            className={`mt-1 ${
              contactForm.errors.phoneNumber &&
              contactForm.touched.phoneNumber &&
              "error"
            }`}
            name="phoneNumber"
            value={contactForm.values.phoneNumber}
            onChange={(e) => {
              contactForm.setFieldValue("phoneNumber", e);
            }}
          />
        </div>
        <div className="form-group w-full mt-5 xl:mt-0 lg:mt-0">
          <label className="text-base font-proxima-semibold text-black">
            Subject <span className="text-red-500">*</span>
          </label>
          <Select
            options={subjectOptions}
            className="react-select mt-1"
            classNamePrefix={` ${
              contactForm.errors.subject && contactForm.touched.subject
                ? "subject_error"
                : "subject"
            }`}
            placeholder="Subject"
            value={contactForm.values.subject}
            onChange={(e) => {
              contactForm.setFieldValue("subject", e);
            }}
          />
        </div>
      </div>
      <div className="form-group mt-5">
        <label className="text-base font-proxima-semibold text-black">
          Message <span className="text-red-500">*</span>
        </label>
        <textarea
          rows={3}
          className={
            contactForm.errors.message && contactForm.touched.message
              ? "w-full block border border-red-300 rounded mt-1 px-4 py-2 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
              : "w-full block border border-[#E5E5E5] rounded mt-1 px-4 py-2 font-proxima-semibold placeholder:font-proxima-regular text-base placeholder:text-sm"
          }
          placeholder="Write your message"
          name="message"
          value={contactForm.values.message}
          onChange={contactForm.handleChange}
        />
      </div>
      <Button
        type="submit"
        disabled={loading}
        className="mt-5 shadow-none hover:shadow-none text-base text-white bg-logoOrange normal-case font-proxima-semibold font-normal rounded py-2"
      >
        {loading ? "Please wait..." : "Submit details"}
      </Button>
    </form>
  );
};
export default ContactUsForm;
