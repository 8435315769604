import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

/* ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const BlogBox = (props) => {
  return (
    <div className="relative">
      <img
        src={props.image}
        className="w-full h-[30vh] object-cover shadow-md rounded-xl group-hover:shadow-xl transition-all duration-300 ease-in-out"
        alt={props.title}
      />
      <div className="absolute top-0 left-0 w-full h-full rounded-xl bg-gradient-to-b from-transparent to-logoPurple p-3 flex items-end">
        <div className="w-full bg-white rounded-xl px-5 py-3 transition-all duration-300 ease-in-out">
          <h5 className="text-black group-hover:text-logoOrange font-proxima-semibold text-lg leading-snug transition-all duration-300 ease-in-out tracking-tight">
            {props.title}
          </h5>
        </div>
      </div>
    </div>
  );
};

const Blogs = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      <Helmet>
        <title>Blogs | Joy of others Support Services</title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <div className="relative">
              <img
                src={require("../../assets/images/banners/blogs-banner.jpg")}
                className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
                alt="Blogs"
              />
            </div>
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-1/2 lg:w-1/2">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-6xl lg:text-6xl leading-none">
                    Discover Blogs with <br />
                    <span className="text-logoOrange">Joy of Others</span>
                  </h1>
                  <h2 className="font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    Elevate Lives with Empathetic NDIS Support - Explore
                    Insights, Stories, and Resources on Joy of Others' Blog Page
                    Today!
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* BLOGS LIST */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5 xl:gap-10 lg:gap-10">
            <div className="col-span-1">
              <Link
                className="block group"
                to="/blogs/bringing-comfort-the-essence-of-nursing-with-joy-of-others"
              >
                <BlogBox
                  image={require("../../assets/images/blogs/blog-01.jpg")}
                  title="Bringing Comfort: The Essence of Nursing with Joy of Others"
                />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Blogs;
