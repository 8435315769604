import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const BlogDetails = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      <Helmet>
        Bringing Comfort: The Essence of Nursing with Joy of Others | Joy of
        Others
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <div className="relative">
              <img
                src={require("../../../assets/images/banners/blogs-banner.jpg")}
                className="w-full h-[35vh] xl:h-[40vh] lg:h-[40vh] object-cover rounded-2xl"
                alt="Blogs"
              />
            </div>
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-2/3 lg:w-2/3">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-6xl lg:text-6xl leading-none">
                    Bringing Comfort: The Essence of Nursing with{" "}
                    <span className="text-logoOrange">Joy of Others</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CONTENT */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <p className="text-lg font-proxima-regular text-black tracking-tight">
            In the ever-evolving landscape of healthcare, the concept of nursing
            care has transcended the traditional boundaries of hospitals and
            found its way into the heart of homes. Joy of Others, a dedicated
            support services organization, recognizes the significance of
            providing nursing care in the comfort of one's own abode. This blog
            delves into the essence of nursing care at home and how Joy of
            Others is making a positive impact in this arena.
          </p>
          <div className="mt-5">
            <h5 className="text-2xl font-proxima-bold text-black">
              The Importance of Nursing Care at Home
            </h5>
            <ol className="ml-8 mt-3 list-decimal">
              <li className="text-lg font-proxima-regular text-black tracking-tight">
                <b>Personalized Attention:</b> Home-based nursing care ensures
                that individuals receive personalized attention tailored to
                their unique needs. Joy of Others strives to create a customized
                care plan for each client, recognizing their specific health
                requirements and preferences.
              </li>
              <li className="text-lg font-proxima-regular text-black tracking-tight mt-3">
                <b>Familiar Surroundings:</b> Being in a familiar environment
                contributes significantly to the overall well-being of
                individuals. Home nursing care allows clients to stay in the
                comfort of their homes, surrounded by the people and things they
                love.
              </li>
              <li className="text-lg font-proxima-regular text-black tracking-tight mt-3">
                <b>Enhanced Quality of Life:</b> By receiving nursing care at
                home, individuals can maintain a sense of independence and
                autonomy, leading to an improved quality of life. Joy of Others
                focuses on empowering clients to live life on their terms.
              </li>
            </ol>
          </div>
          <div className="mt-5">
            <h5 className="text-2xl font-proxima-bold text-black">
              Joy of Others' Approach to Nursing Care at Home
            </h5>
            <ol className="ml-8 mt-3 list-decimal">
              <li className="text-lg font-proxima-regular text-black tracking-tight">
                <b>Comprehensive Assessment:</b> The journey begins with a
                thorough assessment of the client's health needs. Joy of Others'
                experienced nursing team evaluates medical history, current
                conditions, and lifestyle to create a holistic care plan.
              </li>
              <li className="text-lg font-proxima-regular text-black tracking-tight mt-3">
                <b>Skilled and Compassionate Nurses:</b> The organization takes
                pride in its team of skilled and compassionate nurses who bring
                expertise and empathy to every home. Whether it's medication
                management, wound care, or specialized treatments, Joy of Others
                ensures top-notch healthcare services.
              </li>
              <li className="text-lg font-proxima-regular text-black tracking-tight mt-3">
                <b>Family Involvement:</b> Recognizing the importance of a
                supportive network, Joy of Others encourages family involvement
                in the caregiving process. Collaborative efforts between
                healthcare professionals and families create a nurturing
                environment for the clients.
              </li>
            </ol>
          </div>
          <div className="mt-5">
            <h5 className="text-2xl font-proxima-bold text-black">
              Success Stories and Testimonials
            </h5>
            <p className="text-lg font-proxima-regular text-black tracking-tight mt-2">
              Joy of Others has become a beacon of hope for many individuals and
              families seeking nursing care at home. The blog highlights success
              stories and testimonials, showcasing real-life examples of how the
              organization has positively impacted the lives of those it serves.
            </p>
          </div>
          <div className="mt-5">
            <h5 className="text-2xl font-proxima-bold text-black">
              Conclusion
            </h5>
            <p className="text-lg font-proxima-regular text-black tracking-tight mt-2">
              Nursing care at home is not just a service; it's a philosophy
              rooted in compassion, dignity, and a commitment to enhancing the
              lives of individuals. Joy of Others stands as a testament to this
              philosophy, bridging the gap between healthcare and the comfort of
              home, one caring gesture at a time.
            </p>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default BlogDetails;
