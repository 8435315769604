import { Link } from "react-router-dom";

/* ICONS */
import { IoMail, IoCall, IoLocation } from "react-icons/io5";
import NewsLetterForm from "../components/forms/newsLetter";

const Footer = () => {
  return (
    <footer className="bg-logoBlue">
      {/* TOP FOOTER */}
      <div className="py-10">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-6 gap-5">
            {/* ABOUT JOY OF OTHERS */}
            <div className="col-span-2">
              <div className="w-full xl:w-3/4 lg:w-3/4">
                <div className="bg-white rounded-md w-fit p-2">
                  <Link to="/">
                    <img
                      src={require("../assets/images/logo.png")}
                      className="w-[30vh] h-[8vh] object-contain"
                      alt="Joy of others suport services"
                    />
                  </Link>
                </div>
                <div className="mt-5">
                  <a
                    href="mailto:info@joyofothers.com.au"
                    className="w-fit flex items-center gap-3 text-base font-proxima-regular text-white custom-underline white"
                  >
                    <IoMail className="w-5 h-5 text-white" />
                    info@joyofothers.com.au
                  </a>
                  <a
                    href="tel:+61425170827"
                    className="w-fit flex items-center gap-3 text-base font-proxima-regular text-white mt-5 custom-underline white"
                  >
                    <IoCall className="w-5 h-5 text-white" />
                    +61 (425) 170-827
                  </a>
                  <p className="w-fit flex items-start gap-3 text-base font-proxima-regular text-white mt-5">
                    <IoLocation className="w-5 h-5 text-white" /> Brisbane,
                    Logan and Ipswich
                  </p>
                </div>
              </div>
            </div>

            {/* SERVICES */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <h5 className="text-lg font-proxima-bold text-white">SERVICES</h5>
              <div className="mt-5">
                <Link
                  to="/services/assist-personal-activities"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Assist Personal Activities
                </Link>
                <Link
                  to="/services/travel-transport"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Assist Travel/Transport
                </Link>
                <Link
                  to="/services/daily-tasks"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Daily Tasks/Shared Living
                </Link>
                <Link
                  to="/services/community-participation"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Community Participation
                </Link>
              </div>
            </div>

            {/* SERVICES */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <h5 className="text-lg font-proxima-bold text-white">SERVICES</h5>
              <div className="mt-5">
                <Link
                  to="/services/life-skills"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Development Life Skills
                </Link>
                <Link
                  to="/services/household-tasks"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Household tasks
                </Link>
                <Link
                  to="/services/centre-activities"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Group/Centre Activities
                </Link>
                <Link
                  to="/services/accomodation"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Accomodation
                </Link>
              </div>
            </div>

            {/* QUICK LINKS */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <h5 className="text-lg font-proxima-bold text-white">
                QUICK LINKS
              </h5>
              <div className="mt-5">
                <Link
                  to="/"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Home
                </Link>
                <Link
                  to="/about-us"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  About us
                </Link>
                <Link
                  to="/what-is-ndis"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  What is NDIS?
                </Link>
                <Link
                  to="/contact-us"
                  className="w-fit block text-base font-proxima-regular text-white hover:text-logoYellow transition-all duration-300 ease-in-out mt-3"
                >
                  Contact us
                </Link>
              </div>
            </div>

            {/* NEWSLETTER */}
            <div className="col-span-2 xl:col-span-1 lg:col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <h5 className="text-lg font-proxima-bold text-white">
                OUR NEWSLETTER
              </h5>
              <p className="text-base font-proxima-regular text-white text-opacity-70 mt-2">
                Stay connected with our services by subscribing to our
                newsletter.
              </p>

              <NewsLetterForm />
            </div>
          </div>
        </div>
      </div>

      {/* COPYRIGHT FOOTER */}
      <div className="border-t border-white border-opacity-30 py-5">
        <div className="container mx-auto">
          <div className="block xl:flex lg:flex items-center justify-between">
            <p className="text-white font-proxima-regular text-base">
              Copyright © 2024 Joy of Others. All Rights Reserved
            </p>
            <p className="text-white font-proxima-regular text-base">
              Designed by{" "}
              <a
                href="https://www.roundtechsquare.com/"
                target="_blank"
                className="text-base text-red-400"
                rel="noreferrer"
              >
                RoundTechSquare
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
