import axios from 'axios';
import { SERVER_URL } from '../config/index';

const request = axios.create({
  baseURL: SERVER_URL,
  timeout: 1000000,
});

request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);


/* CONTACT US API */
export const contactUsAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/contact-us`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* NEWSLETTER API */
export const newsletterAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/news-letter`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
