import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Collapse,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Button,
} from "@material-tailwind/react";

/* ICONS */
import { Bars2Icon } from "@heroicons/react/24/outline";

/* NAVIGATION DATA */
const navigationData = [
  {
    id: 1,
    link: "/services/assist-personal-activities",
    label: "Assist Personal Activities",
  },
  {
    id: 2,
    link: "/services/travel-transport",
    label: "Assist Travel/Transport",
  },
  {
    id: 3,
    link: "/services/daily-tasks",
    label: "Daily Tasks/Shared Living",
  },
  {
    id: 4,
    link: "/services/community-participation",
    label: "Community Participation",
  },
  {
    id: 5,
    link: "/services/life-skills",
    label: "Development Life Skills",
  },
  {
    id: 6,
    link: "/services/household-tasks",
    label: "Household Tasks",
  },
  {
    id: 7,
    link: "/services/centre-activities",
    label: "Group/Centre Actvities",
  },
  {
    id: 8,
    link: "/services/accomodation",
    label: "Accomodation",
  },
];

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuAccordion, setMenuAccordion] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`bg-white main-header ${
        scrollPosition > 50 ? "fixed slide-in" : "relative"
      } py-2 w-full z-50`}
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-2 xl:grid-cols-4 lg:grid-cols-4 items-center">
          {/* LOGO */}
          <div className="col-span-1">
            <Link to="/" className="block">
              <img
                src={require("../assets/images/logo.png")}
                className="w-[25vh] h-[6vh] object-fill"
                alt="Joy of others support services"
              />
            </Link>
          </div>

          {/* NAVIGATION LINKS */}
          <div className="col-span-2 hidden xl:flex lg:flex items-center justify-center gap-10">
            <Link
              to="/"
              className="text-base text-black font-proxima-semibold hover:text-logoOrange transition-all duration-300 ease-in-out custom-underline"
            >
              Home
            </Link>
            <Link
              to="/about-us"
              className="text-base text-black font-proxima-semibold hover:text-logoOrange transition-all duration-300 ease-in-out custom-underline"
            >
              About Us
            </Link>
            <Menu
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
              allowHover
            >
              <MenuHandler>
                <p className="text-base text-black font-proxima-semibold cursor-pointer hover:text-logoOrange transition-all duration-300 ease-in-out custom-underline">
                  Services
                </p>
              </MenuHandler>
              <MenuList className="w-[30vh] p-2 hover:outline-none">
                {navigationData.map((nav) => (
                  <MenuItem
                    className="group py-3 px-4 bg-transparent hover:bg-black hover:bg-opacity-5"
                    key={nav.id}
                  >
                    <Link
                      to={nav.link}
                      className="text-base text-black font-proxima-semibold group-hover:text-logoOrange transition-all duration-300 ease-in-out"
                    >
                      {nav.label}
                    </Link>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Link
              to="/what-is-ndis"
              className="text-base text-black font-proxima-semibold hover:text-logoOrange transition-all duration-300 ease-in-out custom-underline"
            >
              What is NDIS?
            </Link>
            {/* <Link
              to="/blogs"
              className="text-base text-black font-proxima-semibold hover:text-logoOrange transition-all duration-300 ease-in-out custom-underline"
            >
              Blogs
            </Link> */}
            <Link
              to="/contact-us"
              className="text-base text-black font-proxima-semibold hover:text-logoOrange transition-all duration-300 ease-in-out custom-underline"
            >
              Contact Us
            </Link>
          </div>

          {/* APPOINTENT BOOKING */}
          <div className="col-span-1 hidden xl:flex lg:flex items-center justify-end">
            <a
              href="https://calendly.com/info-j-ct/30min"
              target="_blank"
              rel="noreferrer"
            >
              <Button className="w-fit normal-case font-medium text-base font-proxima-semibold text-white bg-logoPurple hover:bg-opacity-90 shadow-none hover:shadow-none">
                Schedule an appointment
              </Button>
            </a>
          </div>

          {/* MOBILE MENU ICON */}
          <div className="col-span-1 flex items-center justify-end xl:hidden lg:hidden">
            <Button
              className="bg-transparent shadow-none hover:shadow-none border border-[#E5E5E5] p-2 rounded-full hover:bg-black hover:bg-opacity-10 block xl:hidden lg:hidden"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <Bars2Icon className="w-4 h-4 text-black" />
            </Button>
          </div>
        </div>
      </div>

      {/* MOBILE MENU */}
      <Collapse
        open={menuOpen}
        placement="bottom"
        animate={{
          mount: { opacity: 1, y: 0 },
          unmount: { opacity: 0, y: 25 },
        }}
      >
        <div className="p-5 bg-white">
          <Link
            href="/"
            className="block text-black font-proxima-semibold text-base hover:text-logo"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            Home
          </Link>
          <Link
            to="/about-us"
            className="block text-black font-proxima-semibold text-base hover:text-logo mt-3 xl:mt-0 lg:mt-0"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            About us
          </Link>
          <div
            className="block text-black font-proxima-semibold text-base hover:text-logo mt-3 xl:mt-0 lg:mt-0"
            onClick={() => setMenuAccordion(!menuAccordion)}
          >
            Services
          </div>
          <Collapse open={menuAccordion}>
            <div className="ml-5">
              {navigationData.map((nav) => (
                <Link
                  to={nav.link}
                  className="block text-base text-black font-proxima-semibold group-hover:text-logoOrange transition-all duration-300 ease-in-out mt-3"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  {nav.label}
                </Link>
              ))}
            </div>
          </Collapse>
          <Link
            to="/what-is-ndis"
            className="block text-black font-proxima-semibold text-base hover:text-logo mt-3 xl:mt-0 lg:mt-0"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            What is NDIS?
          </Link>
          <Link
            to="/blogs"
            className="block text-black font-proxima-semibold text-base hover:text-logo mt-3 xl:mt-0 lg:mt-0"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            Blogs
          </Link>
          <Link
            to="/contact-us"
            className="block text-black font-proxima-semibold text-base hover:text-logo mt-3 xl:mt-0 lg:mt-0"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            Contact us
          </Link>
        </div>
      </Collapse>
    </header>
  );
};

export default Header;
