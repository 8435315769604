import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import CallToAction from "../../components/call-to-action";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const CentreActivities = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* PAGE TITLE */}
      <Helmet>
        <title>Centre Activities | Joy of others Support Services</title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/center-activities/center-activities-banner.jpeg"
              className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
              alt="About Us"
            />
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-1/2 lg:w-1/2">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-5xl lg:text-5xl leading-tight">
                    Let’s Create Lasting Memories
                  </h1>
                  <h2 className="font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    Empowering lives through compassionate NDIS support
                    services, we at Joy of Others are dedicated to fostering
                    joy, inclusion, and well-being.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT JOY OF OTHERS */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black">
                CENTER ACTIVITIES
              </h4>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                Ever noticed how being around people and sharing experiences
                just makes life better? At Joy of Others, we totally get that.
                That’s why we offer a range of group and center activities
                designed to help you feel connected and included.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                Our center is your space to foster new friendships, participate
                in activities, and grow your interests. It's all about coming
                together, sharing laughs, learning new skills, and just enjoying
                life in a community setting.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                These are some of the group activities we conduct at Joy of
                Others:
              </div>
              <ul
                style={{ listStyleType: "disc" }}
                className="mt-5 px-10 font-proxima-regular text-black text-lg"
              >
                <li>
                  <span className="font-proxima-bold">Social Events:</span>{" "}
                  There's always something exciting happening.
                </li>
                <li className="mt-2">
                  <span className="font-proxima-bold">Creative Workshops:</span>{" "}
                  If painting, crafting, or other DIY activities interest you,
                  this is where you can shine.
                </li>
                <li className="mt-2">
                  <span className="font-proxima-bold">
                    Recreational Activities:
                  </span>{" "}
                  Play games, boost your physical fitness levels, and
                  participate in sports activities to keep you active and
                  entertained.
                </li>
                <li className="mt-2">
                  <span className="font-proxima-bold">
                    Educational Sessions:
                  </span>{" "}
                  There's never enough learning. Upgrade your skills or learn
                  new things.
                </li>
              </ul>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                All of our activities are designed with three things in mind:
                they're fun, they help build your confidence, you develop
                stronger social skills, and you create meaningful relationships.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                At Joy of Others, we’re more than just a service; we’re a
                community that cares. Whether you’re looking to make friends,
                learn something new, or just have a good time, our group and
                center activities are the perfect way to get involved and feel
                part of something special. Let's make great memories together!
              </div>
            </div>
            <div className="col-span-1">
              <div className="col-span-1">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/center-activities/center-activities-01.jpeg"
                      className="w-full h-[35vh] object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/center-activities/center-activities-02.jpeg"
                      className="w-full h-[35vh] object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/center-activities/center-activities-03.jpeg"
                      className="w-full h-[35vh] object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/center-activities/center-activities-04.jpeg"
                      className="w-full h-[35vh] object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <motion.div variants={animation} className="bg-[#F5F5F5] bg-opacity-50">
        <CallToAction />
      </motion.div>
    </motion.section>
  );
};

export default CentreActivities;
