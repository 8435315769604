import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import CallToAction from "../../components/call-to-action";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const LifeSkills = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* PAGE TITLE */}
      <Helmet>
        <title>Life Skills | Joy of others Support Services</title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/life-skills/life-skills-banner.jpeg"
              className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
              alt="About Us"
            />
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-1/2 lg:w-1/2">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-5xl lg:text-5xl leading-tight">
                    Nurture Growth, Master Skills with Our Dedicated Support
                  </h1>
                  <h2 className="font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    Find out what you are capable of and get a chance to become
                    an independent individual equipped with the necessary
                    skills. Succeed in learning the key life skills through our
                    tailored assistance.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT JOY OF OTHERS */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black">
                DEVELOPMENT LIFE SKILLS
              </h4>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                We believe that the right set of skills enable us to live a
                fulfilling and autonomous life. Joy of Others supports you in
                building those essential life skills so you can live a
                significantly more independent life. If you struggle to manage
                everyday tasks, then our Life Skills Development program can
                change things for you. It'll make you feel more confident every
                day.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                The focus of our program is mostly practical skills, with the
                intention of making your routine life easier. Our team can help
                you learn skills like managing personal finances, improving
                communication, and learning efficient household management.
                Since each of our needs is different, our team personalizes the
                plan for you in a manner that meets your individual needs and
                goals.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                Take charge of your life!
              </div>
            </div>
            <div className="col-span-1">
              <div className="col-span-1">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/life-skills/life-skills-01.jpeg"
                      className="w-full h-[40vh] object-cover rounded-xl shadow-md"
                      alt="Development Life Skills"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/life-skills/life-skills-02.jpeg"
                      className="w-full h-[40vh] object-cover rounded-xl shadow-md"
                      alt="Development Life Skills"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <motion.div variants={animation} className="bg-[#F5F5F5] bg-opacity-50">
        <CallToAction />
      </motion.div>
    </motion.section>
  );
};

export default LifeSkills;
