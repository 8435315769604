/* REACT IMPORTS */
import React from "react";
import ReactDOM from "react-dom/client";

/* MATERIAL TAILWIND THEMEPROVIDER */
import { ThemeProvider } from "@material-tailwind/react";

/* MAIN APPLICATION */
import Routes from "./routes/routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Routes />
    </ThemeProvider>
  </React.StrictMode>
);
