import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import CallToAction from "../../components/call-to-action";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const CommunityParticipation = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* PAGE TITLE */}
      <Helmet>
        <title>Community Participation | Joy of others Support Services</title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/community-participation/community-participation-banner.jpeg"
              className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
              alt="About Us"
            />
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-3/5 lg:w-3/5">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-5xl lg:text-5xl leading-tight">
                    We Cherish and Uphold the Principles of Inclusion and a
                    Sense of Belonging
                  </h1>
                  <h2 className="font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    Be a part of our diverse society and get involved in
                    valuable activities in a friendly atmosphere that encourages
                    connection, knowledge sharing, and development.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT JOY OF OTHERS */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <div className="col-span-1">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/community-participation/community-participation-01.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Community Participation"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/community-participation/community-participation-02.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Community Participation"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black">
                COMMUNITY PARTICIPATION
              </h4>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                At Joy of Others, we believe that you should be able to engage
                in your community, and to that end, we can provide you with the
                best Community Engagement services from some of the most
                talented individuals.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                We understand that feeling connected and involved in your
                community is essential for your overall well-being. That's why
                our team is here to support you in building the skills and
                confidence you need to engage with the world around you.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                Our Community Engagement services are very versatile since we
                offer them based on your specific requirements. If you want to
                be involved in clubs and other groups, volunteering, or
                participating in other community activities, we can assist. All
                our personnel consist of well-trained volunteers who remain
                close to you as your companion throughout the process.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                It doesn’t end with the mere provision of help to enable you to
                participate; it is about enabling you to gain vital life skills
                in the process. From vocal and spoken to problem solving and
                decision-making, we’ve got you covered to help you unleash your
                best.
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <motion.div variants={animation} className="bg-[#F5F5F5] bg-opacity-50">
        <CallToAction />
      </motion.div>
    </motion.section>
  );
};

export default CommunityParticipation;
