import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import CallToAction from "../../components/call-to-action";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const PersonalActivity = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* PAGE TITLE */}
      <Helmet>
        <title>
          Assist Personal Activities | Joy of others Support Services
        </title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/personal-activities/personal-activites-banner.jpeg"
              className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
              alt="About Us"
            />
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-3/5 lg:w-3/5">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-5xl lg:text-5xl leading-tight">
                    We empower your Independence
                  </h1>
                  <h2 className="font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    Individualized assistance in organizing your daily life and
                    activities as simply as possible. Succeed in your routines
                    while we help you succeed.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT JOY OF OTHERS */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black">
                ASSISTANCE WITH PERSONAL ACTIVITIES
              </h4>
              <div className="mt-5 font-proxima-regular text-greyText text-lg">
                At Joy of Others, we are committed to providing excellent
                support to maintain your personal autonomy and enhance your
                quality of life. The services we offer are individualized so you
                receive the greatest amount of assistance in the daily tasks
                that you might have trouble with. From dressing up to bathing,
                preparing meals, or needing help moving around, our caring staff
                is always here for you.
              </div>
              <div className="mt-5 font-proxima-regular text-greyText text-lg">
                We aim to offer sufficient support in order for people to gain a
                higher degree of independence and happiness in their daily
                lives. Our experienced caregivers are in a good position to
                understand your individual needs and wants and prescribe a care
                plan that enhances independence and respect for the patient.
              </div>
              <div className="mt-5 font-proxima-regular text-greyText text-lg">
                Unlike being a simple service provider, Joy of Others is with
                you in your process of becoming empowered. We are dedicated to
                offering excellent services and ensuring that your needs are met
                as per your experience. You can count on us to be there for you,
                helping you comfortably and confidently get through each day
                that life has in store for you.
              </div>
            </div>
            <div className="col-span-1">
              <div className="col-span-1">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/personal-activities/personal-activities-01.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Assistance with Personal Activities"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/personal-activities/personal-activities-02.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Assistance with Personal Activities"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <motion.div variants={animation} className="bg-[#F5F5F5] bg-opacity-50">
        <CallToAction />
      </motion.div>
    </motion.section>
  );
};

export default PersonalActivity;
