/* IMAGES */
import Service01 from "../assets/images/services/personal-activities.jpg";
import Service02 from "../assets/images/services/travel-assistance.jpg";
import Service03 from "../assets/images/services/daily-tasks.jpg";
import Service04 from "../assets/images/services/community-participation.jpg";
import Service05 from "../assets/images/services/development-life-skills.jpg";
import Service06 from "../assets/images/services/household-tasks.jpg";
import Service07 from "../assets/images/services/group-center-activities.jpg";
import Service08 from "../assets/images/services/accomodation.jpg";

const services = [
  {
    id: 1,
    link: "/services/assist-personal-activities",
    image: Service01,
    name: "Assistance with Personal Activities",
    description:
      "At Joy of Others, we strive to provide the right level of support to enable you to perform daily activities with a sense of independence and fulfillment.",
  },
  {
    id: 2,
    image: Service02,
    link: "/services/travel-transport",
    name: "Assistance with Travel/Transport",
    description:
      "At Joy of Others, we strive to promote and assist our clients to gain greater independence and increased community connection through travel and transport.",
  },
  {
    id: 3,
    image: Service03,
    link: "/services/daily-tasks",
    name: "Daily Tasks / Shared Living",
    description:
      "At Joy of Others, we provide assistance and/or supervision with daily tasks in a shared living environment with the aim of empowering you to live your life.",
  },
  {
    id: 4,
    image: Service04,
    link: "/services/community-participation",
    name: "Community Participation",
    description:
      "At Joy of Others, we are providing highly skilled Community Engagement to assist clients to build their skills to actively participate in their community.",
  },
  {
    id: 5,
    image: Service05,
    link: "/services/life-skills",
    name: "Development Life Skills",
    description:
      "At Joy of Others, our emphasis is increasing your independence to allow you to live your life with as much autonomy and fulfilment as possible.",
  },
  {
    id: 6,
    image: Service06,
    link: "/services/household-tasks",
    name: "Household Tasks",
    description:
      "At Joy of Others, we understand the importance of maintaining a safe, clean and happy home. Household tasks and chores are part of life and must be performed every day.",
  },
  {
    id: 7,
    image: Service07,
    link: "/services/centre-activities",
    name: "Group / Centre Activities",
    description:
      "At Joy of Others, we understand importance of being connected to others. The connection and community inclusion help to promote good physical and mental health.",
  },
  {
    id: 8,
    image: Service08,
    link: "/services/accomodation",
    name: "Accommodation / Tenancy",
    description:
      "At Joy of Others, we know how important it is to live in a home where you feel safe, comfortable and supported. We help you in getting along with new house.",
  },
];

export default services;
