/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";

/* REACT TOASTIFY */
import { Bounce, ToastContainer } from "react-toastify";

/* STYLES */
import "../styles/global.css";

/* PAGES */
import Homepage from "../pages/homepage";
import ContactUs from "../pages/contact-us";
import AboutUs from "../pages/about-us";
import PersonalActivity from "../pages/services/personal-activity";
import TravelTransport from "../pages/services/travel-transport";
import DailyTasks from "../pages/services/daily-tasks";
import CommunityParticipation from "../pages/services/community-participation";
import LifeSkills from "../pages/services/life-skills";
import HouseholdTasks from "../pages/services/household-tasks";
import CentreActivities from "../pages/services/centre-activities";
import Accomodation from "../pages/services/accomodation";
import WhatisNDIS from "../pages/what-is-ndis";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogs/bringing-comfort-the-essence-of-nursing-with-joy-of-others/index";

import "react-toastify/dist/ReactToastify.css";

/* LAYOUT */
import Layout from "../layout/index";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <AnimatePresence>
      <div className="app">
        <Router>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Homepage />
                </Layout>
              }
            />
            <Route
              path="/services/assist-personal-activities"
              element={
                <Layout>
                  <PersonalActivity />
                </Layout>
              }
            />
            <Route
              path="/services/travel-transport"
              element={
                <Layout>
                  <TravelTransport />
                </Layout>
              }
            />
            <Route
              path="/services/daily-tasks"
              element={
                <Layout>
                  <DailyTasks />
                </Layout>
              }
            />
            <Route
              path="/services/community-participation"
              element={
                <Layout>
                  <CommunityParticipation />
                </Layout>
              }
            />
            <Route
              path="/services/life-skills"
              element={
                <Layout>
                  <LifeSkills />
                </Layout>
              }
            />
            <Route
              path="/services/household-tasks"
              element={
                <Layout>
                  <HouseholdTasks />
                </Layout>
              }
            />
            <Route
              path="/services/centre-activities"
              element={
                <Layout>
                  <CentreActivities />
                </Layout>
              }
            />
            <Route
              path="/services/accomodation"
              element={
                <Layout>
                  <Accomodation />
                </Layout>
              }
            />
            <Route
              path="/contact-us"
              element={
                <Layout>
                  <ContactUs />
                </Layout>
              }
            />{" "}
            <Route
              path="/about-us"
              element={
                <Layout>
                  <AboutUs />
                </Layout>
              }
            />{" "}
            <Route
              path="/what-is-ndis"
              element={
                <Layout>
                  <WhatisNDIS />
                </Layout>
              }
            />{" "}
            <Route
              path="/blogs"
              element={
                <Layout>
                  <Blogs />
                </Layout>
              }
            />{" "}
            <Route
              path="/blogs/bringing-comfort-the-essence-of-nursing-with-joy-of-others"
              element={
                <Layout>
                  <BlogDetails />
                </Layout>
              }
            />{" "}
          </Routes>
        </Router>
        <ToastContainer
          theme="light"
          closeOnClick
          autoClose={4000}
          hideProgressBar={false}
          transition={Bounce}
          position="top-right"
        />
      </div>
    </AnimatePresence>
  );
};

export default AppRoutes;
