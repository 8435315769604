import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ICONS */
import { FaInstagram, FaUserNurse } from "react-icons/fa";
import { HiBadgeCheck } from "react-icons/hi";
import { Ri24HoursFill } from "react-icons/ri";

/* ASSETS */
import Banner from "../../assets/images/banners/banner-01.jpg";

/* USER DEFINED COMPONENTS */
import GetInTouch from "../../components/call-to-action";
import ServiceBox from "../../components/service-box";

/* INSTAGRAM POST */
import InstaPost01 from "../../assets/images/instagram/post-01.jpg";
import InstaPost02 from "../../assets/images/instagram/post-02.jpg";
import InstaPost03 from "../../assets/images/instagram/post-03.jpg";
import InstaPost04 from "../../assets/images/instagram/post-04.jpg";
import InstaPost05 from "../../assets/images/instagram/post-05.jpg";
import InstaPost06 from "../../assets/images/instagram/post-06.jpg";

/* DATA */
import services from "../../data/services";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const Homepage = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="relative">
          <img
            src={Banner}
            className="w-full h-[60vh] xl:h-[88vh] lg:h-[88vh] rounded-2xl object-cover"
            alt="Providing Services for the Elderly and Individuals with Disabilities under MY AGED CARE & NDIS."
          />

          {/* BANNER CONTENT */}
          <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent flex items-center">
            <div className="container mx-auto">
              <div className="w-full xl:w-4/5 lg:w-4/5">
                <h5 className="text-lg xl:text-2xl lg:text-2xl font-proxima-semibold text-white tracking-tight">
                  Join a community where you’re valued and supported every step
                  of the way
                </h5>
                <h1 className="text-4xl xl:text-6xl lg:text-6xl font-proxima-extrabold text-white leading-tight mt-5">
                  Empowering People with Disabilities Through Comprehensive
                  Support
                </h1>
                <p className="w-4/5 text-lg xl:text-2xl lg:text-2xl font-proxima-regular text-white mt-5 text-opacity-80">
                  Our mission is to enable persons with disabilities to lead
                  happy, productive lives in a welcoming environment.
                </p>
                <a
                  href="https://calendly.com/info-j-ct/30min"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="w-fit mt-10 normal-case font-medium text-base font-proxima-semibold text-black bg-white hover:bg-opacity-90 shadow-none hover:shadow-none">
                    Schedule an appointment
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT US */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <h5 className="text-xl font-proxima-semibold text-black">
                About us
              </h5>
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black tracking-tight leading-tight xl:leading-normal lg:leading-normal">
                WELCOME TO <span className="text-logoOrange">JOY OF</span>{" "}
                <span className="text-logoPurple">OTHERS</span>{" "}
              </h4>
              <div className="mt-5">
                <p className="text-lg font-proxima-regular text-black">
                  As an Australian organization promoting disability equality,
                  Joy of Others provides not just useful practical knowledge for
                  the disabled but also dependable emotional support. As
                  stakeholders committed to establishing a more fair society, we
                  strive for change. Our fundamental mission is to provide the
                  greatest quality of disability services in Brisbane, Logan and
                  Ipswich, allowing you to embrace the happiness of your
                  choosing and participate in a variety of activities.
                </p>
                <p className="text-lg font-proxima-regular text-black mt-5">
                  We value the dignity of everyone we serve and ensure you have
                  access to enriching experiences that will help you thrive. At
                  Joy of Others, it is not only our job but also our privilege
                  to positively impact your life by providing exceptional care
                  to those with disabilities.
                </p>
                <Link to="/about-us">
                  <Button className="mt-5 bg-logoBlue shadow-none hover:shadow-none text-sm font-proxima-semibold text-white normal-case">
                    Learn more
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col-span-1">
              <div className="grid grid-cols-2 gap-5">
                <div className="col-span-1">
                  <img
                    src={require("../../assets/images/about-clinic-02.jpg")}
                    className="w-full h-auto object-cover rounded-xl shadow-md"
                    alt="Welcome to Joy of Others"
                  />
                </div>
                <div className="col-span-1">
                  <img
                    src={require("../../assets/images/about-clinic.jpg")}
                    className="w-full h-auto object-cover rounded-xl shadow-md"
                    alt="Welcome to Joy of Others"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* SERVICES */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12 bg-logoBlue bg-opacity-5"
      >
        <div className="container mx-auto">
          <h5 className="text-xl font-proxima-semibold text-black">
            Our Services
          </h5>
          <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black tracking-tight leading-tight xl:leading-normal lg:leading-normal">
            HOW WE CAN <span className="text-logoOrange">HELP YOU</span>
          </h4>
          <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-8 gap-5 xl:gap-8 lg:gap-8 mt-5">
            {services.map((service) => (
              <div className="col-span-1" key={service.id}>
                <ServiceBox
                  image={service.image}
                  name={service.name}
                  desc={service.description}
                  link={service.link}
                />
              </div>
            ))}
          </div>
        </div>
      </motion.div>

      {/* WHY CHOOSE US */}
      <motion.div variants={animation} className="pt-6 xl:pt-12 lg:pt-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <img
                src={require("../../assets/images/why-choose-us.jpg")}
                className="w-full h-auto object-cover rounded-xl shadow-md"
                alt="Why choose Joy of Others"
              />
            </div>
            <div className="col-span-1">
              <h5 className="text-xl font-proxima-semibold text-black">
                Providing Care and Support
              </h5>
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black tracking-tight leading-tight xl:leading-normal lg:leading-normal">
                WHY CHOOSE <span className="text-logoOrange">JOY OF</span>{" "}
                <span className="text-logoPurple">OTHERS</span>{" "}
              </h4>
              <div className="mt-5">
                <p className="text-lg font-proxima-regular text-black">
                  As a person-centered organisation, our clients are constantly
                  at the heart of our actions. At our organization, we seek to
                  assist people in reaching their full potential and improving
                  their entire quality of life. We aim to provide you with a
                  voice and the care you deserve from us.
                </p>
                <p className="text-lg font-proxima-regular text-black mt-5">
                  We always prioritize our clients' demands in all we do. In
                  other words, we strive to make a good and useful difference in
                  the lives of everyone with whom we interact. We are devoted to
                  connecting with you and offering services that meet your
                  requirements and expectations.
                </p>
                <div className="mt-5">
                  <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                    <div className="col-span-1 flex items-center gap-3 bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-lg px-5 py-3">
                      <HiBadgeCheck className="w-8 h-8 text-logoOrange" />
                      <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                        Quality Care
                      </h5>
                    </div>
                    <div className="col-span-1 flex items-center gap-3 bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-lg px-5 py-3">
                      <FaUserNurse className="w-8 h-8 text-logoOrange" />
                      <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                        Nursing Care
                      </h5>
                    </div>
                    <div className="col-span-1 flex items-center gap-3 bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-lg px-5 py-3">
                      <Ri24HoursFill className="w-8 h-8 text-logoOrange" />
                      <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                        24 Hours Service
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* OUR VALUES */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black tracking-tight leading-tight xl:leading-normal lg:leading-normal">
            OUR VALUES
          </h4>
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 xl:gap-10 lg:gap-10 mt-5">
            <div className="col-span-1">
              <div className="w-full rounded-lg py-5 px-8 bg-logoOrange bg-opacity-5 border border-logoOrange border-opacity-25">
                <h5 className="text-2xl font-proxima-bold text-black tracking-tight">
                  We recognise you
                </h5>
                <p className="text-lg font-proxima-regular text-black mt-2">
                  We are cognizant of the individual differences and demands of
                  each participant. In order to help you get the best results,
                  our knowledgeable team takes the time to learn about you and
                  your objectives.
                </p>
              </div>
            </div>
            <div className="col-span-1">
              <div className="w-full rounded-lg py-5 px-8 bg-logoOrange bg-opacity-5 border border-logoOrange border-opacity-25">
                <h5 className="text-2xl font-proxima-bold text-black tracking-tight">
                  We esteem you
                </h5>
                <p className="text-lg font-proxima-regular text-black mt-2">
                  All of our clients will receive sincere, individualised
                  attention from our small team. Every one of our team
                  participants has a specific Account Manager, as we ensure
                  proper service.
                </p>
              </div>
            </div>
            <div className="col-span-1">
              <div className="w-full rounded-lg py-5 px-8 bg-logoOrange bg-opacity-5 border border-logoOrange border-opacity-25">
                <h5 className="text-2xl font-proxima-bold text-black tracking-tight">
                  Maintain assistance control
                </h5>
                <p className="text-lg font-proxima-regular text-black mt-2">
                  We recognise that each of our participants is an individual
                  with specific needs, and we encourage you to make thoughtful
                  decisions so you can realise your objectives and help you give
                  proper service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* INSTAGRAM WALL */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12 bg-logoBlue bg-opacity-5"
      >
        <div className="container mx-auto">
          <div className="block xl:flex lg:flex items-center justify-between">
            <h5 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold tracking-tight">
              FOLLOW US ON INSTAGRAM
            </h5>
            <a
              href="https://www.instagram.com/joy_of_others"
              className="text-xl xl:text-2xl lg:text-2xl font-proxima-semibold text-black tracking-tight block hover:text-logoOrange transition-all duration-300 ease-in-out"
              target="_blank"
              rel="noreferrer"
            >
              @joy_of_others
            </a>
          </div>
          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
              <div className="col-span-1 relative group">
                <img
                  src={InstaPost01}
                  className="w-full rounded-xl object-cover"
                  alt="Follow us on Instagram"
                />
                <a
                  href="https://www.instagram.com/joy_of_others"
                  className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-xl invisible group-hover:visible"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="w-10 h-10 text-white" />
                </a>
              </div>
              <div className="col-span-1">
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                  <div className="col-span-1 relative group">
                    <img
                      src={InstaPost02}
                      className="w-full rounded-xl object-cover"
                      alt="Follow us on Instagram"
                    />
                    <a
                      href="https://www.instagram.com/joy_of_others"
                      className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-xl invisible group-hover:visible"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaInstagram className="w-10 h-10 text-white" />
                    </a>
                  </div>
                  <div className="col-span-1 relative group">
                    <img
                      src={InstaPost03}
                      className="w-full rounded-xl object-cover"
                      alt="Follow us on Instagram"
                    />
                    <a
                      href="https://www.instagram.com/joy_of_others"
                      className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-xl invisible group-hover:visible"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaInstagram className="w-10 h-10 text-white" />
                    </a>
                  </div>
                  <div className="col-span-1 relative group">
                    <img
                      src={InstaPost04}
                      className="w-full rounded-xl object-cover"
                      alt="Follow us on Instagram"
                    />
                    <a
                      href="https://www.instagram.com/joy_of_others"
                      className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-xl invisible group-hover:visible"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaInstagram className="w-10 h-10 text-white" />
                    </a>
                  </div>
                  <div className="col-span-1 relative group">
                    <img
                      src={InstaPost05}
                      className="w-full rounded-xl object-cover"
                      alt="Follow us on Instagram"
                    />
                    <a
                      href="https://www.instagram.com/joy_of_others"
                      className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-xl invisible group-hover:visible"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaInstagram className="w-10 h-10 text-white" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-span-1 relative group">
                <img
                  src={InstaPost06}
                  className="w-full rounded-xl object-cover"
                  alt="Follow us on Instagram"
                />
                <a
                  href="https://www.instagram.com/joy_of_others"
                  className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-xl invisible group-hover:visible"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="w-10 h-10 text-white" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GET IN TOUCH */}
      <GetInTouch />
    </motion.section>
  );
};

export default Homepage;
