import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import CallToAction from "../../components/call-to-action";

/* ASSETS */
import AboutUsBanner from "../../assets/images/banners/about-us.jpg";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const AboutUs = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* PAGE TITLE */}
      <Helmet>
        <title>About Us | Joy of others Support Services</title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <img
              src={AboutUsBanner}
              className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
              alt="About Us"
            />
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-3/5 lg:w-3/5">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-6xl lg:text-6xl leading-none">
                    Discover the Compassionate Heart of{" "}
                    <span className="text-logoOrange">Joy of Others</span>
                  </h1>
                  <h2 className="font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    More than just a service provider, we're a kind community
                    committed to enhancing lives with sincere support and
                    caring.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT JOY OF OTHERS */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <h4 className="text-2xl xl:text-3xl lg:text-3xl font-proxima-extrabold text-black leading-tight">
                <span className="text-logoRed">EMPOWERING LIVES</span> AND
                MAKING MIRACLES WITH
              </h4>
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-logoOrange">
                JOY OF OTHERS{" "}
                <span className="text-logoPurple">SUPPORT SERVICES</span>
              </h4>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                We are driven by the belief that everyone deserves a chance to
                succeed, regardless of their circumstances. As a disability
                service provider, we are passionate about empowering individuals
                from all walks of life to achieve their full potential. We
                support our customers, their families and carers – in many
                unique ways that give them the opportunity to explore their
                interests, do what they love and live comfortably. We are the
                best serving disability service providers, dedicated to helping
                people with disabilities live an empowered life.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                Joy of others support services is not your typical
                not-for-profit; we are savvy, innovative, fast-paced and beaming
                with pride for the work we do. Joy of others are proud leaders
                in our field and have fostered a reputation for best-practice
                approaches to service delivery under the NDIS. The Afford
                difference is not only our relentless commitment to delivering
                the highest quality support on offer in the disability sector,
                but that at Joy of Others support services, we genuinely care.
              </div>
            </div>
            <div className="col-span-1">
              <div className="col-span-1">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <img
                      src={require("../../assets/images/about-clinic-02.jpg")}
                      className="w-full h-auto object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src={require("../../assets/images/about-clinic.jpg")}
                      className="w-full h-auto object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <CallToAction />
    </motion.section>
  );
};

export default AboutUs;
