import { motion } from "framer-motion";

/* MATERIAL TAILWIND COMPONENTS */
import ContactUsForm from "./forms/contactUs";

/* ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const CallToAction = () => {
  return (
    <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
      <div className="container mx-auto">
        <div className="w-full">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 items-center">
            <div className="col-span-1">
              <h5 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold tracking-tight">
                GET IN TOUCH
              </h5>
              <div className="mt-5">
                <h5 className="text-xl font-proxima-bold">
                  Visit us at our place
                </h5>
                <p className="text-base font-proxima-regular text-black mt-1">
                  Come say hello at our place for personalized care and wellness
                  excellence.
                </p>
                <p className="block text-lg xl:text-xl lg:text-xl font-proxima-semibold text-logoOrange tracking-tight mt-1">
                  Brisbane, Logan and Ipswich
                </p>
              </div>
              <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                <div className="col-span-1">
                  <div className="mt-0">
                    <h5 className="text-xl font-proxima-bold">Chat with us</h5>
                    <p className="text-base font-proxima-regular text-black mt-1">
                      Our friendly staff is here to help.
                    </p>
                    <a
                      href="mailto:info@joyofothers.com.au"
                      target="_blank"
                      className="block text-lg xl:text-xl lg:text-xl font-proxima-semibold text-logoOrange mt-1 tracking-tight"
                      rel="noreferrer"
                    >
                      info@joyofothers.com.au
                    </a>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="mt-0">
                    <h5 className="text-xl font-proxima-bold">
                      Schedule appointment
                    </h5>
                    <p className="text-base font-proxima-regular text-black mt-1">
                      Call us on Mon-Fri from 8am to 5pm
                    </p>
                    <a
                      href="tel:+61425170827"
                      target="_blank"
                      className="block text-lg xl:text-xl lg:text-xl font-proxima-semibold text-logoOrange mt-1 tracking-tight"
                      rel="noreferrer"
                    >
                      +61 (425) 170-827
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <h5 className="text-xl font-proxima-bold">
                  Hours of operation
                </h5>
                <p className="text-base font-proxima-regular text-black mt-1">
                  Discover our hours: Open daily for your convenience. Check our
                  schedule!
                </p>
                <div className="mt-2">
                  <p className="text-base font-proxima-regular text-black">
                    <span className="font-proxima-bold">Monday - Friday:</span>{" "}
                    8:00 AM to 7:00 PM{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CallToAction;
