import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import CallToAction from "../../components/call-to-action";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const HouseholdTasks = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* PAGE TITLE */}
      <Helmet>
        <title>Household Tasks | Joy of others Support Services</title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/household-tasks/household-tasks-banner.jpeg"
              className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
              alt="About Us"
            />
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-3/5 lg:w-3/5">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-5xl lg:text-5xl leading-tight">
                    Turning Chores into Child’s Play with Special Assistance
                  </h1>
                  <h2 className="font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    Optimize home chores and design a pleasant home environment.
                    Have the comfort and warmth of home while we offer you the
                    assistance you require.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT JOY OF OTHERS */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <div className="col-span-1">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/household-tasks/household-tasks-01.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/household-tasks/household-tasks-02.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black">
                ASSISTANCE WITH HOUSEHOLD TASKS
              </h4>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                A safe and clean home feels like a happy home. It is what makes
                us feel comfortable and keeps our well-being in check. Joy of
                Others makes maintaining that easy for you. We cannot do away
                with household chores, and managing them all can become
                frustrating or overwhelming at times. Our comprehensive
                assistance with household tasks is your rescue.
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                Our House Tasks support team ensures your home feels comfortable
                and practical for your needs. We'll help you accomplish even the
                smallest of household chores, like cleaning, laundry, or
                organizing your space. If a feeling of sense and order is what
                brings you calm, we're with you!
              </div>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                Besides helping you finish your household chores, our team will
                also empower you to take an active role in your household. We
                provide guidance and encouragement, helping you develop the
                skills to perform tasks independently whenever possible. At Joy
                of Others, we are dedicated to supporting you in maintaining a
                home that reflects your needs and enhances your daily life.
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <motion.div variants={animation} className="bg-[#F5F5F5] bg-opacity-50">
        <CallToAction />
      </motion.div>
    </motion.section>
  );
};

export default HouseholdTasks;
