import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const ServiceBox = (props) => {
  return (
    <div className="bg-white border border-[#E5E5E5] rounded-xl shadow-lg">
      <img
        src={props.image}
        className="w-full rounded-t-xl object-cover h-[25vh]"
        alt={props.name}
      />
      <div className="p-5">
        <h5 className="text-lg font-proxima-bold text-black tracking-tight">
          {props.name}
        </h5>
        <p className="text-base font-proxima-regular mt-2">{props.desc}</p>
        <Link to={props.link}>
          <Button className="mt-4 shadow-none hover:shadow-none text-base text-white bg-logoOrange normal-case font-proxima-semibold font-normal rounded-md py-2">
            Learn more
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ServiceBox;
