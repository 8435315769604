import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

/* USER DEFINED COMPONENTS */
import CallToAction from "../../components/call-to-action";

/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const Accomodation = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* PAGE TITLE */}
      <Helmet>
        <title>Accomodation | Joy of others Support Services</title>
      </Helmet>

      {/* BANNER */}
      <motion.div variants={animation} className="p-5">
        <div className="container-full-width">
          <div className="relative">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/accomodation/accomodation-banner.jpeg"
              className="w-full h-[35vh] xl:h-[50vh] lg:h-[50vh] object-cover rounded-2xl"
              alt="About Us"
            />
            <div className="absolute top-0 left-0 w-full h-full rounded-2xl bg-gradient-to-r from-black to-transparent">
              <div className="container mx-auto flex items-center h-full">
                <div className="w-full xl:w-3/5 lg:w-3/5">
                  <h1 className="text-white font-proxima-extrabold text-4xl xl:text-5xl lg:text-5xl leading-tight">
                    Build Your Home Sweet Home Together with Our Disability
                    Support
                  </h1>
                  <h2 className="font-proxima-semibold italic text-lg xl:text-2xl lg:text-2xl text-white mt-5 leading-normal">
                    Empowering lives through compassionate NDIS support
                    services, we at Joy of Others are dedicated to fostering
                    joy, inclusion, and well-being.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT JOY OF OTHERS */}
      <motion.div variants={animation} className="py-6 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1">
              <div className="col-span-1">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/accomodation/accomodation-01.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                  <div className="col-span-1">
                    <img
                      src="https://storage.googleapis.com/cdn-roundtechsquare/joy-of-others/accomodation/accomodation-02.jpeg"
                      className="w-full h-[45vh] object-cover rounded-xl shadow-md"
                      alt="Welcome to Joy of Others"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <h4 className="text-3xl xl:text-4xl lg:text-4xl font-proxima-extrabold text-black">
                ACCOMODATION
              </h4>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                What is a home that doesn't feel cozy and comfortable? The team
                at Joy of Others wants to help you make the process of settling
                into your new home simple and stress-free. There's a lot we have
                to offer:
              </div>
              <ul
                style={{ listStyleType: "disc" }}
                className="mt-5 px-10 font-proxima-regular text-black text-lg"
              >
                <li>
                  <span className="font-proxima-bold">
                    Finding the Right Home:
                  </span>{" "}
                  We help locate accommodation that suits your needs, budget,
                  and preferences.
                </li>
                <li className="mt-2">
                  <span className="font-proxima-bold">
                    Understanding Agreements:
                  </span>{" "}
                  We guide you through tenancy agreements so you know your
                  rights and responsibilities.
                </li>
                <li className="mt-2">
                  <span className="font-proxima-bold">Setting Up:</span> From
                  utilities to furniture, we assist in getting your new place
                  ready for you.
                </li>
                <li className="mt-2">
                  <span className="font-proxima-bold">Settling In:</span> We
                  provide support as you adjust to your new home, making sure
                  you feel comfortable and secure.
                </li>
              </ul>
              <div className="mt-5 font-proxima-regular text-black text-lg">
                Moving into a new home is more than just a transition—it’s the
                start of a new chapter. Joy of Others makes sure your new home
                is both welcoming and supportive. Let us help you find a place
                where you can truly feel at home.
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <motion.div variants={animation} className="bg-[#F5F5F5] bg-opacity-50">
        <CallToAction />
      </motion.div>
    </motion.section>
  );
};

export default Accomodation;
